import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Marquee from '~/components/common/Marquee';
import Modal from '~/components/common/Modal';
import NewsletterForm from '~/components/navbar/Newsletter';
import WalletButton from '~/components/navbar/WalletButton';
import { OPENSEA_COLLECTION_URL, TOURNAMENTS } from '~/constants';

// import { isBattleActive } from '~/utils/utils';
import MusicPlayer from '../musicPlayer/MusicPlayer';

type MainNavProps = {
  showConnectWallet?: boolean;
  playMusic?: boolean;
};

const MainNav = ({ showConnectWallet, playMusic }: MainNavProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    // toggle open newsletter if query param present
    const params = new URLSearchParams(window.location.search);
    if (params.has('newsletter')) {
      setModalOpen(true);
    }
  }, []);

  const onHandleClick = (e) => {
    if (e.currentTarget.parentNode.classList.contains('is-active')) {
      e.currentTarget.parentNode.classList.remove('is-active');
    } else {
      e.currentTarget.parentNode.classList.add('is-active');
    }
  };
  const isSomethingHappening = false;
  const activeTournament = Object.values(TOURNAMENTS).find((t) => t.isActive);

  return (
    <>
      <div
        className={`landing-page__error-banner ${
          errorMessage && 'landing-page__error-banner--visible'
        }`}
      >
        <div className="landing-page__error-banner-inside">{errorMessage}</div>
      </div>
      <nav className="landing-page__main-nav">
        <div className="landing-page__main-nav-toggle" onClick={onHandleClick}>
          <div className="menu-toggle">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="landing-page__main-nav-inner">
          <div className="landing-page__main-nav-social">
            <Link href="/" passHref>
              <a
                className={
                  router.pathname == '/' ?
                    'main-nav-nav__item main-nav-nav__item--active' :
                    'main-nav-nav__item'
                }
              >
                Home
              </a>
            </Link>
            <Link href="/collection" passHref>
              <a
                className={
                  router.pathname.includes('/collection') ?
                    'main-nav-nav__item main-nav-nav__item--active' :
                    'main-nav-nav__item'
                }
              >
                Collection
              </a>
            </Link>
            <Link href="/leaderboard" passHref>
              <a
                className={
                  router.pathname.includes('leaderboard') ?
                    'main-nav-nav__item main-nav-nav__item--active' :
                    'main-nav-nav__item'
                }
              >
                Leaderboard
              </a>
            </Link>
            <div className="main-nav__group">
              <a href="#" className="main-nav-nav__item">
                Tournaments
              </a>
              <div className="main-nav__group__items main-nav-nav__item--updated">
                <Link href="/tournaments" passHref>
                  <a
                    className={
                      router.pathname == '/tournaments' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Overview
                  </a>
                </Link>
                <Link href="/tournaments/signup" passHref>
                  <a
                    className={
                      router.pathname == '/signup' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Signup
                  </a>
                </Link>
                <Link
                  href={`/tournaments/battles/${activeTournament?.id}`}
                  passHref>
                  <a
                    className={
                      router.pathname.includes('battles') ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Battles
                  </a>
                </Link>
                <Link
                  // href="/tournaments/valentines"
                  href={`/tournaments/${activeTournament?.id}`}
                  passHref>
                  <a
                    className={
                      router.pathname == `/tournaments/${activeTournament?.id}` ?
                      // router.pathname == '/tournaments/valentines' ?

                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Brackets
                  </a>
                </Link>
                {/* <Link href="/tournaments/signup" passHref>
                  <a
                    className={
                      router.pathname == '/tournaments/signup' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Signup
                  </a>
                </Link> */}
                {/* <Link href="/tournaments/champions" passHref>
                  <a
                    className={
                      router.pathname == '/tournaments/champions' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Tournament of Champions
                  </a>
                </Link>
                <Link href="/tournaments/peaceful" passHref>
                  <a
                    className={
                      router.pathname == '/tournaments/peaceful' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Tournament of the Peaceful
                  </a>
                </Link>
                <Link href="/tournaments/fallen" passHref>
                  <a
                    className={
                      router.pathname == '/tournaments/fallen' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Tournament of the Fallen
                  </a>
                </Link> */}

              </div>
            </div>
          </div>
          <div className="landing-page__main-nav-utility">
            {/* <div className="landing-page__prompt-mint">Mint: Sold out!</div> */}
            <div className="main-nav__group">
              <a href="#" className="main-nav-nav__item">
                NFT Holders
              </a>
              <div className="main-nav__group__items main-nav-nav__item--updated">
                <Link href="/revival" passHref>
                  <a
                    className={
                      router.pathname == '/revival' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Revival
                  </a>
                </Link>
                <Link href="/name" passHref>
                  <a
                    className={
                      router.pathname == '/name' ?
                        'main-nav-nav__item main-nav-nav__item--active' :
                        'main-nav-nav__item'
                    }
                  >
                    Name thy Warrior
                  </a>
                </Link>
              </div>
            </div>
            <div
              className="main-nav-nav__item"
              onClick={() => setModalOpen(true)}
            >
              Join Newsletter
            </div>
            <div
              className={
                modalOpen ?
                  'modal--open modal-wrapper' :
                  'modal--close modal-wrappe'
              }
            >
              <Modal
                isOpen={modalOpen}
                closeTimeoutMS={1000}
                handleClose={() => setModalOpen(false)}
                className="modal__overlay"
              >
                <NewsletterForm setModalOpen={setModalOpen} />
              </Modal>
            </div>

            <div className="landing-page__main-nav-utility-socials">
              <Link href="https://twitter.com/peacefallxyz" passHref>
                <a className="main-nav-nav__item">
                  <img src="/img/icn.twitter_white.svg" alt="Twitter Icon" />
                </a>
              </Link>
              <Link href="https://discord.gg/M6MvmVRbuh" passHref>
                <a className="main-nav-nav__item">
                  <img src="/img/icn.discord_white.svg" alt="Intagram Icon" />
                </a>
              </Link>
              <Link href={OPENSEA_COLLECTION_URL} passHref>
                <a className="main-nav-nav__item">
                  <img src="/img/icn.opensea_white.svg" alt="OpenSea Icon" />
                </a>
              </Link>
              <MusicPlayer playMusic={playMusic}/>
            </div>
            {showConnectWallet && (
              <WalletButton setErrorMessage={setErrorMessage} />
            )}
          </div>
        </div>
        <div className="landing-page__main-nav-utility mobile-only">
          {showConnectWallet && (
            <WalletButton setErrorMessage={setErrorMessage} />
          )}
        </div>
      </nav>
      {isSomethingHappening && (
        <Marquee text="Tournament sign up is live! Sign up by Tuesday, August 2nd @ 10am Eastern&nbsp;&nbsp;&nbsp;&nbsp;◆" />
      )}
      {/* {isSomethingHappening && (
        <Marquee text="Life and Death Battle 5 is Live! Submit attack by Thursday, June 16 @ 2pm Eastern&nbsp;&nbsp;&nbsp;&nbsp;◆" />
      )} */}
    </>
  );
};

export default MainNav;
