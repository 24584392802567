import { useEffect, useRef, useState } from 'react';

import Button from '../common/Button';

const MusicPlayer = ({ playMusic }: {playMusic?: boolean}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasBeenPausedByUser, setHasBeenPausedByUser] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const icon = isPlaying ? 'pause' : 'play';
  function handleClick() {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }

  useEffect(() => {
    if (playMusic && !hasBeenPausedByUser) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playMusic, hasBeenPausedByUser]);

  return (
    <>
      <div className="player-container">
        <Button
          className="player-button"
          id="player-button"
          onClick={() => {
            handleClick();
            setHasBeenPausedByUser(true);
          }}
          label={<img src={`/img/btn.${icon}.svg`} alt={icon} />}
        />
        <audio loop ref={audioRef} id="soundrack-audio">
          <source src="/peacefall.mp3" type="audio/mpeg" />
        </audio>
        {/* {Boolean(playMusic) && (
          <audio autoPlay>
            <source src="/peacefall.mp3" type="audio/mpeg" />
          </audio>
        )} */}
      </div>
    </>
  );
};

export default MusicPlayer;
