/* eslint-disable camelcase */

import {
  Attacks,
  Attribute,
  AttributeKeys,
  BatchAttackTypes,
  Chronicle,
  CombatEntry,
  ContextResultsResponse,
  PairingType,
  Potion,
  ResultEntry,
  TournamentResultsByRoundItem,
  TPairingWarrior,
  TTournamentParams,
  VALID_ATTACKS,
  Warrior,
  WarriorBattle,
  WarriorImages,
} from '~/../api/common.types';
import {
  AUTH_COOKIE_NAME,
  CURRENT_ACTIVE_BATTLE,
  CURRENT_BATTLE_ENDS,
  isQA,
  TOURNAMENTS,
} from '~/constants';
import { getDefaultProvider } from '~/server/useDefaultProvider';

const baseCDN = process.env.NEXT_PUBLIC_BASE_CDN;
const potionAddress = process.env.NEXT_PUBLIC_POTION_CONTRACT_ADDRES || '';
const peacefallAddress = process.env.NEXT_PUBLIC_PEACEFALL_ADDRESS || '';

// Shortens user wallet
export const shortenedAddress = (address: string) => {
  if (!address) return '';
  return `${address.slice(0, 5)}...${address.slice(-4)}`;
};

const supportedChainId = process.env.NEXT_PUBLIC_CHAIN_ID;

export const getAttackByBattle = (
  warrior: Warrior,
  tournamentParam: TTournamentParams,
  battle: number,
): string => {
  const {
    warrior_json: { context_chronicles },
  } = warrior;
  const contextId = TOURNAMENTS[tournamentParam]?.apiContext;
  const contextChronicles: ContextResultsResponse = context_chronicles[contextId];
  // will be undefined if a bye
  if (!contextChronicles) return '';
  const battleChronicle = contextChronicles.find((chron) => chron.state === battle);
  if (!battleChronicle) return '';

  const attackChosen = battleChronicle.entries.find(
    (entry) => entry.warrior.id == warrior.warrior_id
  )?.current_attack || '';

  return attackChosen;
};

export const calculateAttackBonus = (
  attack: string,
  warrior: Warrior,
  tournamentParam: TTournamentParams,
  battle: number,
): number => {
  const {
    warrior_json: { context_chronicles },
  } = warrior;
  const syndicate = warrior.warrior_json.attributes.find(
    (attribute: Attribute) => attribute.trait_type === 'Syndicate'
  )?.value;

  let bonus = 0;

  if (syndicate?.toLowerCase() === attack.toLowerCase()) {
    bonus += 3;
  }
  const contextId = TOURNAMENTS[tournamentParam]?.apiContext;
  const contextChronicle: ContextResultsResponse = context_chronicles[contextId];
  // if no contextChronicle, means they were in a bye
  if (contextChronicle && contextChronicle.length) {
    const lastChronicle = contextChronicle.find((chron) => chron.state === (battle - 1));
    // const lastChronicle = contextChronicle[contextChronicle.length - 1];
    if (lastChronicle) {
      const lastAttack =
        lastChronicle.entries.find(
          (entry) => entry.warrior.id == warrior.warrior_id
        )?.current_attack || '';
      // eslint-disable-next-line camelcase
      warrior.warrior_json.attributes.find(
        ({ trait_type }) => trait_type === 'Syndicate'
      )?.value;
      if (
        syndicate === 'Renegade' &&
        lastAttack.toLowerCase() !== attack.toLowerCase()
      ) {
        bonus += 6;
      }
      if (
        syndicate === 'Spirit' &&
        lastAttack.toLowerCase() === attack.toLowerCase()
      ) {
        bonus += 6;
      }
    }
  }
  return bonus;
};

export const validNetwork = (chainId) => {
  let checkChainId = 1;
  if (typeof supportedChainId === 'string') {
    checkChainId = parseInt(supportedChainId, 10);
  } else if (typeof supportedChainId === 'number') {
    checkChainId = supportedChainId;
  }
  // note: walletConnect only shows `chainId` 1 (ethereum) even if on rinkeby
  return checkChainId === chainId;
};

export const gameCookieName = (address: string) => {
  return `${address}-${AUTH_COOKIE_NAME}`;
};

export function getAttribute(
  attributes: [Attribute] | Attribute[],
  attr: AttributeKeys
) {
  const type = attributes.filter((attribute) => {
    return attribute.trait_type === attr;
  });

  return type[0].value;
}

export async function getFormattedAddress(address) {
  // const providerOptions = {
  //   etherscan: process.env.NEXT_PUBLIC_ETHERSCAN_API_KEY,
  //   infura: process.env.NEXT_PUBLIC_INFURA_ID,
  // };
  // const provider = ethers.getDefaultProvider('homestead', providerOptions);
  const provider = getDefaultProvider();
  const ensName = await provider.lookupAddress(address || '');
  return ensName || shortenedAddress(address || '');
}

// now that levels are one-based, need to decrement for image path resolution
export function decrementLevel(level) {
  if (level === 0) return 0;
  return level - 1;
}

export function isWarAndDeathRound(battle: number) {
  return battle >= 3;
}
// implment to obfuscate opponent
export function isLifeAndDeathRound(_battle: number) {
  return false;
}

export function capitalizeFirstLetter(string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function isDefaultSyndicate(syndicate) {
  return ['metal', 'fire', 'timber', 'water', 'earth'].includes(
    syndicate.toLowerCase()
  );
}

export function getAttack(entry: ResultEntry) {
  if (entry.current_attack) {
    return entry.current_attack;
  } else {
    return isDefaultSyndicate(entry.warrior.syndicate) ?
      entry.warrior.syndicate :
      '';
  }
}

export function wasAttackSelectd(entry: ResultEntry) {
  return entry.current_attack;
}

export function generateImages(
  warrior: Warrior,
  level: string | number | undefined
): WarriorImages {
  const basePath = `${baseCDN}/${warrior.warrior_id}`;
  const character = warrior.warrior_json.attributes.find(
    ({ trait_type }) => trait_type === 'Character'
  )?.value;

  const characterKey = character?.toLowerCase().replace(' ', '-');
  return {
    active: `${basePath}/${decrementLevel(level)}.clear.gif`,
    notActive: `${basePath}/${decrementLevel(level)}.base.png`,
    dead: `/img/dead/${characterKey}.png`,
    stunned: `/img/defeated/${characterKey}.png`,
  };
}

export function generatePairingImages(warrior: TPairingWarrior): WarriorImages {
  const basePath = `${baseCDN}/${warrior.id}`;
  const characterKey = warrior.character.toLowerCase().replace(' ', '-').replace('_', '-');
  const level = warrior.level;
  return {
    active: `${basePath}/${decrementLevel(level)}.clear.gif`,
    notActive: `${basePath}/${decrementLevel(level)}.base.png`,
    dead: `/img/dead/${characterKey}.png`,
    stunned: `/img/defeated/${characterKey}.png`,
  };
}

// taken from API logic
function died(warrior_id, chronicle: Chronicle): boolean {
  if (!chronicle.fatal) {
    return false;
  }
  if (!chronicle.victor) {
    return false;
  }
  return chronicle.victor != warrior_id;
}

export function getCurrentWarrior(warrior: Warrior): WarriorBattle {
  const id = warrior.warrior_id;
  const hp = warrior.warrior_json.attributes.find(
    (attribute: Attribute) => attribute.trait_type === 'HP'
  )?.value;
  let level: any = warrior.warrior_json.attributes.find(
    (attribute: Attribute) => attribute.trait_type === 'Level'
  )?.value;
  const name = warrior.warrior_json.name;
  const syndicate = warrior.warrior_json.attributes.find(
    (attribute: Attribute) => attribute.trait_type === 'Syndicate'
  )?.value;
  const dead = warrior.warrior_json.attributes.find(
    (attribute: Attribute) => attribute.trait_type === 'Dead'
  )?.value;
  const type = warrior.warrior_json.attributes.find(
    (attribute: Attribute) => attribute.trait_type === 'Type'
  )?.value;
  if (hp === undefined || level === undefined || name === undefined) {
    throw new TypeError(`Warrior was promised to be there ${warrior}`);
  }
  level = parseInt(level, 10);
  // TODO: fix before next battle
  const isDead = dead && dead.toLowerCase() === 'yes' ? true : false;
  const images = generateImages(warrior, level);
  return {
    id,
    hp,
    level,
    name,
    syndicate,
    type,
    dead: isDead,
    images: images,
  };
}

// first two rounds are 0 based, second two are 1 based
// should only be used on chronicle, since NFT itself was updated to be 1 based
// entries: 0, 1, 3, 4;
function calculateCorrectLevel(warriorBattle: CombatEntry, battle: number) {
  const level = warriorBattle.warrior.level;

  if (battle >= 2) {
    return level;
  } else {
    return level + 1;
  }
}

export function getWarriorByBattle(
  warrior: Warrior,
  battle: number
): WarriorBattle {
  const {
    warrior_json: { chronicle },
  } = warrior;
  const chronicleBattle = chronicle.find((chron) => chron.round === battle);
  if (!chronicleBattle) {
    const currentWarrior = getCurrentWarrior(warrior);
    return currentWarrior;
  }
  const isActiveBattle = battle == CURRENT_ACTIVE_BATTLE;
  if (chronicleBattle && isActiveBattle) {
    const currentWarrior = getCurrentWarrior(warrior);
    return currentWarrior;
  }

  const warriorBattle = chronicleBattle.combat_entries.find(
    (entry) => entry.warrior.id === warrior.warrior_id
  );
  if (warriorBattle === undefined) {
    throw new TypeError('Warrior was promised to be there');
  }

  const isDead = died(warrior.warrior_id, chronicleBattle);
  const images = generateImages(warrior, warriorBattle?.warrior.level);
  const correctLevel = calculateCorrectLevel(warriorBattle, battle);
  return {
    id: warriorBattle.warrior.id,
    hp: warriorBattle.warrior.hp,
    level: correctLevel,
    name: warriorBattle.warrior.name,
    syndicate: warriorBattle.warrior.syndicate,
    dead: isDead,
    images: images,
  };
}

export function particpatedInBattle(warrior: Warrior, battleId: number) {
  const {
    warrior_json: { chronicle },
  } = warrior;
  const chronicleBattle = chronicle.find((chron) => chron.round === battleId);
  return chronicleBattle;
}

export function particpatedInWar(warrior: Warrior) {
  const {
    warrior_json: { chronicle },
  } = warrior;
  return chronicle.length >= 4;
  // return chronicleBattle;
}

export function potionType(potion: Potion): 'green' | 'purple' | 'blue' {
  // const color = potion.attributes[0].value;
  const color = potion.attributes.find(
    (attribute) => attribute.trait_type === 'Color'
  )?.value;
  if (color === undefined) {
    throw new TypeError('Potion color was promised to be there');
  }
  return color;
}

export const isWarWarrior = (warrior: Warrior) => {
  const peace = warrior.warrior_json.attributes.find(
    (attribute) => attribute?.trait_type === 'Peace'
  )?.value;
  if (peace && peace.toLowerCase() === 'no') {
    return true;
  }
  return false;
};

export const isAliveWarrior = (warrior: Warrior) => {
  const dead = warrior.warrior_json.attributes.find(
    (attribute) => attribute?.trait_type === 'Dead'
  )?.value;
  if (dead && dead.toLowerCase() === 'no') {
    return true;
  }
  return false;
};

export const isBattleActive = () => {
  return CURRENT_BATTLE_ENDS > new Date().getTime();
};

export const isCurrentBattle = (battleId: number) => {
  return CURRENT_ACTIVE_BATTLE === battleId;
};

export const isBurned = (address: string) => {
  return address === potionAddress;
};

export const isWarriorNamed = (name: string, id: number): boolean => {
  const defaultName = `Peacefall #${id}`;
  return name !== defaultName;
};

export const getWarriorName = (name: string, id: number): string => {
  if (isWarriorNamed(name, id)) {
    return name;
  }
  return `Peacefall #${id}`;
};

export const warriorByLevel = (warrior: Warrior, level: number) => {
  const warriorLevel = getAttribute(warrior.warrior_json.attributes, 'Level');
  return parseInt(warriorLevel, 10) === level;
};

export const openSeaWarriorLink = (warriorId: number) => {
  // TODO: Update to goerli?
  if (isQA) {
    return `https://testnets.opensea.io/assets/rinkeby/${peacefallAddress}/${warriorId}`;
  } else {
    return `https://opensea.io/assets/ethereum/${peacefallAddress}/${warriorId}`;
  }
};

export function getOwnerAndOpponentId(
  ownerAddress: string,
  pairing: PairingType[]
) {
  const owner = pairing.find((pair) => pair.wallet_address === ownerAddress);
  const opponent = pairing.find(
    (pair) => pair.warrior_id !== owner?.warrior_id
  );
  return {
    ownerWarriorId: owner?.warrior_id,
    opponentWarriorId: opponent?.warrior_id,
  };
}

export function generateMatchups(
  ownerAddress: string,
  pairings: PairingType[][]
) {
  const matchups: any = [];
  pairings.map((pairing) => {
    const owner = pairing.find((pair) => pair.wallet_address === ownerAddress);
    const opponent = pairing.find(
      (pair) => pair.warrior_id !== owner?.warrior_id
    );
    // handle owner fighting themselves
    if (owner?.wallet_address === opponent?.wallet_address) {
      matchups.push({
        ownerWarriorId: opponent?.warrior_id,
        opponentWarriorId: owner?.warrior_id,
      });
    }
    matchups.push({
      ownerWarriorId: owner?.warrior_id,
      opponentWarriorId: opponent?.warrior_id,
    });
  });
  return matchups;
}

export function getTournamentWinner(finalRound: TournamentResultsByRoundItem): TPairingWarrior | undefined {
  const winner = finalRound.matchups.flatMap((matchup) => matchup).find((warrior) => warrior.isWinner);
  return winner;
}

export function generateRevealedKey(battle: number, warriorId: number | undefined, tournament: string): string {
  return `battle-${battle}-${tournament}-${warriorId}-result-revealed`;
}

const getLastAttack = (
  warrior: Warrior,
  tournamentParam: TTournamentParams,
  battle: number,
): string => {
  const {
    warrior_json: { context_chronicles },
  } = warrior;
  let lastAttack = '';

  const contextId = TOURNAMENTS[tournamentParam]?.apiContext;
  const contextChronicle: ContextResultsResponse = context_chronicles[contextId];
  // if no contextChronicle, means they were in a bye
  if (contextChronicle && contextChronicle.length) {
    const lastChronicle = contextChronicle.find((chron) => chron.state === (battle - 1));
    if (lastChronicle) {
      lastAttack =
        lastChronicle.entries.find(
          (entry) => entry.warrior.id == warrior.warrior_id
        )?.current_attack || '';

      return lastAttack;
    }
  }
  return lastAttack;
};

// if battle 0, we don't have a first attack, so return random attack for all Syndicate types
const calculateLoyalistAttack = (warrior: Warrior, tournamentParam: TTournamentParams, battle: number): Attacks | undefined => {
  const warriorData = getCurrentWarrior(warrior);
  const syndicate = warriorData.syndicate;
  if (syndicate === 'Spirit') {
    const lasAttack = getLastAttack(warrior, tournamentParam, battle);
    if (lasAttack) {
      return lasAttack as Attacks;
    } else {
      return generateRandomAttack();
    }
  }
  // if renegade, return random attack that is NOT last attack
  if (syndicate === 'Renegade') {
    const lasAttack = getLastAttack(warrior, tournamentParam, battle);
    return generateRandomAttack(lasAttack as Attacks);
  }
  return syndicate?.toLowerCase() as Attacks;
};

const beatsMapper = {
  fire: {
    beats: ['timber', 'metal'],
    losesTo: ['water', 'earth'],
  },
  water: {
    beats: ['fire', 'metal'],
    losesTo: ['earth', 'timber'],
  },
  earth: {
    beats: ['water', 'fire'],
    losesTo: ['timber', 'metal'],
  },
  timber: {
    beats: ['earth', 'water'],
    losesTo: ['fire', 'metal'],
  },
  metal: {
    beats: ['timber', 'earth'],
    losesTo: ['fire', 'water'],
  },
};

const calculateCounterAttack = (syndicate: Attacks): Attacks => {
  const attackMapper = beatsMapper[syndicate.toLowerCase()];
  if (!attackMapper) {
    throw new Error(`Missing attack mapper', ${syndicate}`);
  }
  const randomIndex = Math.floor(Math.random() * attackMapper.losesTo.length);
  const randomAttack = attackMapper.losesTo[randomIndex];
  return randomAttack as Attacks;
};

const calculateAttackAgainstOpponent = (warrior: Warrior): Attacks => {
  const warriorData = getCurrentWarrior(warrior);
  const syndicate = warriorData.syndicate;
  if (syndicate === 'Spirit' || syndicate === 'Renegade') {
    return generateRandomAttack();
  } else {
    return calculateCounterAttack(syndicate as Attacks);
  }
};


const generateRandomAttack = (filterAttack?: Attacks | undefined): Attacks => {
  // possibly filter out last attack for renegade
  const validAttacks = VALID_ATTACKS.filter((attack) => attack !== filterAttack);
  const randomIndex = Math.floor(Math.random() * validAttacks.length);
  const randomAttack = validAttacks[randomIndex];
  return randomAttack;
};


export const calculateBatchAttack = (
  batchAttack: BatchAttackTypes,
  warrior: Warrior,
  opponent: Warrior,
  tournamentParam: TTournamentParams,
  battle: number
): Attacks | undefined => {
  if (batchAttack === 'loyalist') {
    return calculateLoyalistAttack(warrior, tournamentParam, battle);
  }
  if (batchAttack === 'random') {
    return generateRandomAttack();
  }
  if (batchAttack === 'opponent') {
    return calculateAttackAgainstOpponent(opponent);
  }
  if (batchAttack === 'counterCounter') {
    // get counter attack against owner warrior
    const counterAttack = calculateAttackAgainstOpponent(warrior);
    return calculateCounterAttack(counterAttack);
  }
};


// keep around for testing
// export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/* eslint-disable quote-props */
const chracterMapper = {
  badboy: 'badboy',
  'badboy of legend': 'badboy',
  brawler: 'brawler',
  'brawler of legend': 'brawler',
  kickboxer: 'kickboxer',
  'kickboxer of legend': 'kickboxer',
  ninja: 'ninja',
  'ninja of legend': 'ninja',
  ronin: 'ronin',
  'ronin of legend': 'ronin',
  scholar: 'scholar',
  'scholar of legend': 'scholar',
  specter: 'specter',
  'specter of legend': 'specter',
  'the don': 'don',
  'the don of legend': 'don',
  tank: 'tank',
  'tank of legend': 'tank',
};

export const characterMapping = (character: string) => {
  return chracterMapper[character.toLowerCase()];
};
