import { useConnectModal } from '@rainbow-me/rainbowkit';
// import { useAccountModal } from '@rainbow-me/rainbowkit';
// import { useAccount } from 'wagmi';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useConnect, useDisconnect } from 'wagmi';

import Button from '~/components/common/Button';
import { isQA } from '~/constants';
import { deleteCurrentToken } from '~/hooks/auth/useGetCurrentToken';
import { useGetAccount } from '~/hooks/web3/useGetAccount';
// import { shortenedAddress } from '~/utils/utils';

type WalletButtonProps = {
  setErrorMessage: (string) => void;
};

const WalletButton = ({ setErrorMessage }: WalletButtonProps) => {
  const { data: account } = useGetAccount();
  const { error: connectAccountError } = useConnect();
  const { openConnectModal } = useConnectModal();
  // const { openAccountModal } = useAccountModal();
  const { disconnect } = useDisconnect();
  const queryClient = useQueryClient();
  // this does not trigger consistently from WAGMI
  // in future, upgrade versions

  // useAccount({
  //   onDisconnect: async () => {
  //     deleteCurrentToken(account?.address);
  //     await queryClient.invalidateQueries();
  //     await queryClient.refetchQueries();
  //     await disconnect();
  //   },
  // }
  // );


  useEffect(() => {
    if (account && account.address && !account.isValidNetwork) {
      let network = 'Ethereum';
      if (isQA) {
        network = 'Goerli';
      }
      setErrorMessage(`Please connect to ${network} Network`);
    } else {
      setErrorMessage('');
    }
  }, [account, account?.address, account?.isValidNetwork, setErrorMessage]);

  useEffect(() => {
    if (connectAccountError) {
      // keep log around for better sentry scaffolding
      console.log('connectAccountError', connectAccountError);
      const errorAsString =
        typeof connectAccountError === 'string' ?
          connectAccountError :
          connectAccountError?.message;
      switch (errorAsString) {
      // TODO: Look into more errors?
      case 'Modal closed by user': // ignore this error
      case 'User closed modal':
        setErrorMessage('');
        break;
        // TODO: Insufficient funds
      case 'User Rejected':
        setErrorMessage(
          'Rejected by user. (You may need to unlock your wallet).'
        );
        break;
      case 'User denied account authorization':
        setErrorMessage('Transaction rejected by user');
        break;
      default:
        setErrorMessage(errorAsString);
        break;
      }
    }
  }, [connectAccountError, setErrorMessage]);

  // note: wallet disconnect doesn't disconnect on metamask extension, see
  // https://github.com/wagmi-dev/wagmi/issues/684#issuecomment-1179293122
  const disconnectWallet = async () => {
    console.log('disconnect called');
    deleteCurrentToken(account?.address);
    await queryClient.invalidateQueries();
    await queryClient.refetchQueries();
    await disconnect();
  };

  return (
    <>
      {account?.address ? (
        <Button
          className="main-nav-nav__item-btn main-nav-nav__item-btn--connected button"
          // label={account.ensName || shortenedAddress(account.address)}
          label={'Logout'}
          onClick={disconnectWallet}
          // onClick={openAccountModal}

        />
      ) : (
        <a
          className={'main-nav-nav__item main-nav-nav__item--connect button'}
          onClick={openConnectModal}
        >
          Connect Wallet
        </a>
      )}
    </>
  );
};

export default WalletButton;
