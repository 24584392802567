import Cookies from 'js-cookie';
import { useQuery } from 'react-query';

import { gameCookieName } from '~/utils/utils';

export const getCurrentToken = ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { address }] = queryKey;
  const name = gameCookieName(address);
  return Cookies.get(name);
};

export const deleteCurrentToken = (address) => {
  // TODO: Delete this after testing on QA
  // uncomment if ever able to get disconenct trigger working
  // console.log('Cookies.get() 0', Cookies.get());
  // Object.keys(Cookies.get()).forEach(function(cookieName) {
  //   console.log('cookieName', cookieName);
  //   Cookies.remove(cookieName);
  // });
  // console.log('Cookies.get() 1', Cookies.get());
  const name = gameCookieName(address);
  return Cookies.remove(name);
};

export const useGetCurrentToken = ({ address, enabled }: {address: string, enabled: boolean}) => {
  return useQuery(['auth', { address }], getCurrentToken, {
    enabled: enabled,

  });
};
