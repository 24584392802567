import { useState } from 'react';

import Logo from '~/components/common/Logo';
import { useSubmitNewsletter } from '~/hooks/newsletter/useSubmitNewsletter';

function validateEmail(email) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

const NewsletterForm = ({ setModalOpen }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const {
    mutate: setSubmitNewsletter,
    isLoading,
    isSuccess,
    isError,
  } = useSubmitNewsletter();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      return setError(true);
    }
    setSubmitNewsletter({
      email: email,
    });
  };

  return (
    <div className="wrapper--newsletter">
      <div className="modal__header">
        <span
          aria-label="Close modal"
          className="modal__close"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          ✕
        </span>
      </div>
      <div>
        <div className="">
          <div className="newsletter-spacing">
            <Logo filename="logo_white.svg" alt="Peacefall Logo" />
          </div>
          <div className="newsletter-spacing">
            <h2 className="landing-page__h2 form__title">
              <span>Never miss an announcement.</span>
            </h2>
            <h2 className="landing-page__h2 form__title">
              <span>Never get spam.</span>
            </h2>
          </div>
          {isSuccess ? (
            <>
              <p>Success! You&apos;ll be hearing from us.</p>{' '}
            </>
          ) : (
            <>
              <div className="field-oneline">
                <form onSubmit={handleSubmit}>
                  <input
                    className="input newsletter-spacing-small field-oneline__input"
                    value={email}
                    type="email"
                    required={true}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                    autoFocus={true}
                  />
                  {error && <p>Invalid email.</p>}
                  <button
                    className="warrior__attack__confirm__button primary"
                    type="submit"
                    disabled={isLoading ? true : false}
                  >
                    Submit
                  </button>
                </form>
              </div>
              {isLoading && <p>Saving...</p>}
              {isError && <p>Something went wrong, please try again.</p>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterForm;
