import ReactModal from 'react-modal';

ReactModal.setAppElement('#__next');
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.5)';

export default function Modal({
  isOpen,
  handleClose,
  children,
  ...attrs
}: { isOpen: boolean; children: React.ReactNode } & any) {
  return (
    <ReactModal
      isOpen={isOpen}
      {...attrs}
      className={`landing-page__modal ${attrs.className || ''}`}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
    >
      {children}
    </ReactModal>
  );
}
