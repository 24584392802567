import { useQuery } from 'react-query';
import { useAccount, useEnsName, useNetwork, useWalletClient } from 'wagmi';

import { validNetwork } from '~/utils/utils';

export function useGetAccount() {
  const { address: signerAddress, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useWalletClient();
  const { data: ensName } = useEnsName({
    address: signerAddress,
  });
  return useQuery(
    ['getCachedWallet', chain?.id, signerAddress, isConnected],
    async () => {
      const isValidNetwork = chain?.id ? validNetwork(chain?.id) : false;
      return {
        connected: isConnected,
        address: signerAddress,
        chainId: chain?.id,
        signer: signer,
        isValidNetwork: isValidNetwork,
        ensName: ensName,
      };
    },
    {
      enabled: true,
      refetchOnWindowFocus: true,
      retry: true,
    }
  );
}
