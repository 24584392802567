type LogoProps = {
  filename: string,
  alt: string
}

export default function Logo({ filename, alt }: LogoProps) {
  return (
    <div className="main-logo">
      <img src={`/img/${filename}`} alt={alt} />
    </div>
  );
}
