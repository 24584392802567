export default function Button({
  label,
  onClick,
  ...attrs
}: { label: string; onClick: () => void } & any) {
  return (
    <button onClick={() => onClick()} {...attrs}>
      {label}
    </button>
  );
}
