import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { MutationFunction, useMutation } from 'react-query';

type NewsletterParams = {
  email: string;
};

const submitNewsletter: MutationFunction<any, any> = async ({
  email,
}: NewsletterParams) => {
  const payload = { email: email };
  try {
    const { data } = await axios({
      url: '/api/newsletter',
      method: 'post',
      data: payload,
    });
    return data;
  } catch (err: any) {
    console.log('err', err);
    captureException(err);
    throw new Error(err);
  }
};

export const useSubmitNewsletter = () => {
  return useMutation(submitNewsletter, {
    retry: 1,
  });
};
